import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor {

  constructor() { }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const token = localStorage.getItem('token');
    const api_key: string = environment.API_KEY;
    const headers = new HttpHeaders({
      'x-token': `${token}`,
      'x-api-key': `${api_key}`
    });

    const reqClone = req.clone({
      headers
    });

    return next.handle(reqClone);
  }

  manejaError(error: HttpErrorResponse) {
    console.log('Ocurrio un error');
    console.warn(error);
    //throw new Error('Method not implemented.');
    return throwError('Method not implemented.');
  }
}
