import { Injectable } from '@angular/core';
import { CanLoad, Route, Router, UrlSegment, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../public/auth/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanLoad {
  constructor(
    private authService: AuthService,
    private router: Router,
  ) { }

  async canLoad(): Promise<boolean> {
    let token = this.authService.isAuth();
    if (!token) {
      return true;
    } else {
      let user = await this.authService.valitUser();
      if(user) {
        if(user.IDROL){
          this.router.navigateByUrl('/admin/home');
        }
      }
      return false;
    }
  }
}
