import { Injectable } from '@angular/core';
import { CanLoad, Route, Router, UrlSegment, UrlTree } from '@angular/router';
import { AuthService } from '../public/auth/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanLoad {
  constructor(
    private authService: AuthService,
    private router: Router,
  ) { }

  async canLoad(): Promise<boolean> {
    let token = await this.authService.validToken();
    if (token) {
      return true;
    } else {
      localStorage.clear();
      this.router.navigateByUrl('/auth/login');
      return false;
    }
  }

}
