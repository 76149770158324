import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class UiService {

  constructor() { }
  error(title: String) {
    const Toast = Swal.mixin({
      toast: true,
      position: 'top-right',
      iconColor: 'white',
      background: 'rgba(252,87,83,0.8)',
      customClass: {
      },
      buttonsStyling: true,
      showConfirmButton: true,
      timer: 3500,
      timerProgressBar: true,
    })
    Toast.fire({
      icon: 'error',
      title: title,
      confirmButtonText: '<span style="color: rgb(0, 0, 0); font-weight: bold;">Aceptar<span/>',
      confirmButtonColor: 'rgba(255, 255, 255,0.4 )',
    })
  }

  success(title: String) {
    const Toast = Swal.mixin({
      toast: true,
      position: 'top-right',
      iconColor: 'white',
      background: 'rgba(51,199,72,0.8)',
      customClass: {
        popup: 'colored-toast',
        title: 'success-text',
        confirmButton: 'btn-success-ok'
      },
      buttonsStyling: true,
      showConfirmButton: true,
      timer: 3500,
      timerProgressBar: true
    })
    Toast.fire({
      icon: 'success',
      title: title,
      confirmButtonText: '<span style="color: rgb(0, 0, 0); font-weight: bold;">Aceptar<span/>',
      confirmButtonColor: 'rgba(255, 255, 255,0.4 )',
    })
  }

  session() {
    const Toast = Swal.mixin({
      toast: true,
      position: 'top-right',
      iconColor: 'white',
      background: 'rgba(121, 194, 243 ,0.8)',
      customClass: {
        popup: 'colored-toast',
        title: 'info-text',
        confirmButton: 'btn-info-ok'
      },
      buttonsStyling: true,
      showConfirmButton: true,
      timer: 4000,
      timerProgressBar: true
    })

    Toast.fire({
      icon: 'info',
      title: 'Por favor Inicia sesión.',
      confirmButtonText: '<span style="color: rgb(0, 0, 0); font-weight: bold;">Aceptar<span/>',
      confirmButtonColor: 'rgba(255, 255, 255,0.4 )',
    })
  }

  sessionClose() {
    const Toast = Swal.mixin({
      toast: true,
      position: 'top-right',
      iconColor: 'white',
      background: 'rgba(51,199,72,0.7)',
      customClass: {
        popup: 'colored-toast',
        title: 'success-text',
        confirmButton: 'btn-success-ok'
      },
      buttonsStyling: true,
      showConfirmButton: true,
      timer: 3500,
      timerProgressBar: true
    })
    Toast.fire({
      icon: 'success',
      title: 'Sesión finalizada',
      confirmButtonText: '<span style="color: rgb(0, 0, 0); font-weight: bold;">Aceptar<span/>',
      confirmButtonColor: 'rgba(255, 255, 255,0.4 )',
    })
  }

  warning(title: String) {
    const Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: true,
      background: 'rgba(253,188,64,0.8)',
      customClass: {
        title: 'warning-text',
        confirmButton: 'btn-warning-ok'
      }
    });

    Toast.fire({
      confirmButtonText: 'Aceptar',
      title: title,
      timer: 6000,
      timerProgressBar: true
    });
  }

  loading(isLoading: boolean, html: String, title: String) {
    if (isLoading) {
      Swal.fire({
        heightAuto: false,
        title: title,
        html: html,
        timerProgressBar: true,
        didOpen: () => {
          Swal.showLoading();
        },
        didClose: () => {
          Swal.hideLoading();
        }
      });
    } else {
      Swal.close();
    }
  }

  sending(isLoading: boolean, message: string) {
    if (isLoading) {
      Swal.fire({
        heightAuto: false,
        title: 'Procesando solicitud',
        html: `Enviando ${message} ...`,
        timerProgressBar: true,
        didOpen: () => {
          Swal.showLoading();
        },
        didClose: () => {
          Swal.hideLoading();
        }
      });
    } else {
      Swal.close();
    }
  }
}

