import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AbstractControl } from '@angular/forms';
import { LoginResponse } from '../interfaces/login_response';
import { firstValueFrom } from 'rxjs';
import { UiService } from 'src/app/shared/services/ui.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { DefautlResponse } from 'src/app/private/admin/interfaces/defaultresponse';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private http: HttpClient,
    private toast: UiService,
    private router: Router,
    private jwtHelper: JwtHelperService,
  ) { }

  private url: string = environment.url;

  async login(control: AbstractControl) {
    let body = {
      USUARIO: control.get('user')?.value,
      PASSWORD: control.get('password')?.value
    };
    this.toast.loading(true, 'Cargando...', 'Iniciando sesión');
    try {
      let resp = await firstValueFrom(this.http.post<LoginResponse>(`${this.url}/login`, body));
      this.toast.loading(false, 'Cargando...', 'Iniciando sesión');
      if (resp.user) {
        localStorage.setItem('token', `${resp.token}`);
        //console.log(resp);
        return resp;
      } else {
        return resp;
      }
    } catch (err) {
      this.toast.error('Ocurrió un error inesperado, intenta nuevamente.');
      return (err as LoginResponse);
    }
  }

  async validToken() {
    try {
      let res = await firstValueFrom(this.http.post<DefautlResponse>(`${this.url}/auth/valid/token`, {}));
      return res.ok;
    } catch (error) {
      this.toast.session();
      return false;
    }
  }

  async valitUser() {
    try {
      let res = await firstValueFrom(this.http.post<DefautlResponse>(`${this.url}/auth/valid/user`, {}));
      return res.user ?? false;
    } catch (error) {
      this.toast.session();
      return false;
    }
  }

  async logout(): Promise<boolean> {
    localStorage.removeItem('token');
    localStorage.clear();
    let logout = await this.router.navigateByUrl('/auth/login');
    if (logout)
      this.toast.sessionClose();
    return true;
  }

  isAuth(): boolean {
    const token = localStorage.getItem('token') || undefined;
    if (token) {
      if (this.jwtHelper.isTokenExpired(token) || !localStorage.getItem('token')) {
        return false;
      } else {
        return true;
      }
    }
    return false;
  }
}
